import { Context, Effect } from 'effect';
import { auth } from 'firebase-config';

import type {
  ExamHistoryData,
  ExamHistoryResponse,
  SaveStudyProgressPayload,
  StudyProgress,
  WrittenTestProblem,
  WrittenTestProblemCategory,
} from '@pages/api/v2/route/written-test/writtenTestSchema';

import { api } from '@apis/hc';

class APIResponseError extends Error {
  constructor(
    message: string,
    public status: number,
    public statusText: string,
    public url: string,
  ) {
    super(message);
    this.name = 'APIResponseError';
  }
}

class FirebaseGetIdTokenError {
  readonly _tag = 'FirebaseIdTokenError';
  error: unknown;
  constructor(error: unknown) {
    this.error = error;
  }
}

class WrittenTestApiError {
  readonly _tag = 'WrittenTestApiError';
  error: unknown;
  constructor(public message: string, public originalError?: unknown) {}
}

class FirebaseAuthUserService extends Context.Tag('FirebaseAuthUserService')<
  FirebaseAuthUserService,
  {
    readonly idToken: Effect.Effect<string, FirebaseGetIdTokenError>;
  }
>() {}

class WrittenTestApiService extends Context.Tag('WrittenTestApiService')<
  WrittenTestApiService,
  {
    readonly fetchProblemsWithIds: (
      ids: string[],
    ) => Effect.Effect<WrittenTestProblem[], WrittenTestApiError>;
    readonly fetchExamProblems: (
      type: '미니모의고사' | '실전모의고사',
    ) => Effect.Effect<WrittenTestProblem[], WrittenTestApiError, FirebaseAuthUserService>;
    readonly fetchProblemsWithCategory: (
      category: WrittenTestProblemCategory,
      id: string,
      direction: string,
    ) => Effect.Effect<WrittenTestProblem[], WrittenTestApiError>;
    readonly fetchFrequentlyMissedProblems: (
      id: string,
      direction: string,
    ) => Effect.Effect<WrittenTestProblem[], WrittenTestApiError>;
    readonly saveExamResult: (
      data: ExamHistoryData,
    ) => Effect.Effect<
      void,
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly fetchUserExamHistory: () => Effect.Effect<
      ExamHistoryResponse[],
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly fetchUserExamHistoryDetail: (
      id: string,
    ) => Effect.Effect<
      ExamHistoryData,
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly fetchMissedSubmissions: () => Effect.Effect<
      WrittenTestProblem[],
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly bookmarkProblem: (
      id: string,
    ) => Effect.Effect<
      void,
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly fetchBookmarkedProblems: () => Effect.Effect<
      WrittenTestProblem[],
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly saveStudyProgress: (
      payload: SaveStudyProgressPayload,
    ) => Effect.Effect<
      void,
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
    readonly fetchStudyProgress: () => Effect.Effect<
      StudyProgress,
      WrittenTestApiError | FirebaseGetIdTokenError,
      FirebaseAuthUserService
    >;
  }
>() {}

const Implementation = Context.empty().pipe(
  Context.add(FirebaseAuthUserService, {
    idToken: Effect.tryPromise({
      try: async () => {
        const idToken = await auth.currentUser?.getIdToken();
        if (!idToken) throw new FirebaseGetIdTokenError(null);
        return idToken;
      },
      catch: (error) => {
        return new FirebaseGetIdTokenError(error);
      },
    }),
  }),
  Context.add(WrittenTestApiService, {
    fetchExamProblems: (type) =>
      Effect.tryPromise({
        try: async () => {
          const res = await api['written-test'].problems.random.$get({
            query: { type: type === '실전모의고사' ? 'full' : 'mini' },
          });

          if (!res.ok) {
            throw new APIResponseError(
              `API call failed: ${res.status} ${res.statusText}`,
              res.status,
              res.statusText,
              res.url,
            );
          }

          const data = await res.json();
          return data.problems;
        },
        catch: (error) => {
          if (error instanceof APIResponseError) {
            return new WrittenTestApiError(error.message, error);
          }
          return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
        },
      }),
    fetchProblemsWithCategory: (category, id, direction) =>
      Effect.tryPromise({
        try: async () => {
          const res = await api['written-test'].problems.category.$get({
            query: { category, lastDocId: id, direction },
          });

          if (!res.ok) {
            throw new APIResponseError(
              `API call failed: ${res.status} ${res.statusText}`,
              res.status,
              res.statusText,
              res.url,
            );
          }

          const data = await res.json();
          return data.problems;
        },
        catch: (error) => {
          if (error instanceof APIResponseError) {
            return new WrittenTestApiError(error.message, error);
          }
          return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
        },
      }),
    fetchProblemsWithIds: (ids: string[]) =>
      Effect.gen(function* () {
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].problems.ids.$get({
              query: { ids },
            });

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return await res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchFrequentlyMissedProblems: (id, direction) =>
      Effect.tryPromise({
        try: async () => {
          const res = await api['written-test'].problems['frequently-missed'].$get({
            query: { lastDocId: id, direction },
          });

          if (!res.ok) {
            throw new APIResponseError(
              `API call failed: ${res.status} ${res.statusText}`,
              res.status,
              res.statusText,
              res.url,
            );
          }

          const data = await res.json();
          return data.problems;
        },
        catch: (error) => {
          if (error instanceof APIResponseError) {
            return new WrittenTestApiError(error.message, error);
          }
          return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
        },
      }),
    saveExamResult: (data: ExamHistoryData) =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].save.exam.result.$post(
              {
                json: { data },
              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchUserExamHistory: () =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].history.exam.$get(
              {},
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return await res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchUserExamHistoryDetail: (id) =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].history.exam[':id'].$get(
              {
                param: {
                  id,
                },
              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return await res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchMissedSubmissions: () =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].problems.missed.$get(
              {},
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            const data = await res.json();
            return data.problems;
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    bookmarkProblem: (id) =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].problem.bookmark.$post(
              {
                json: {
                  id,
                  createdAt: new Date().toISOString(),
                },
              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchBookmarkedProblems: () =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].problems.bookmarked.$get(
              {},
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            const { problems } = await res.json();
            return problems;
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    saveStudyProgress: (payload) =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].save.progress.$post(
              {
                json: payload,
              },
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
    fetchStudyProgress: () =>
      Effect.gen(function* () {
        const { idToken } = yield* FirebaseAuthUserService;
        const token = yield* idToken;
        return yield* Effect.tryPromise({
          try: async () => {
            const res = await api['written-test'].progress.latest.$get(
              {},
              {
                headers: {
                  authorization: `Bearer ${token}`,
                },
              },
            );

            if (!res.ok) {
              throw new APIResponseError(
                `API call failed: ${res.status} ${res.statusText}`,
                res.status,
                res.statusText,
                res.url,
              );
            }

            return res.json();
          },
          catch: (error) => {
            if (error instanceof APIResponseError) {
              return new WrittenTestApiError(error.message, error);
            }
            return new WrittenTestApiError(`Unexpected error occurred: ${error}`, error);
          },
        });
      }),
  }),
);

const provideImplementation = (
  (impl: typeof Implementation) =>
  <A, E, R>(effect: Effect.Effect<A, E, R>) =>
    Effect.provide(effect, impl)
)(Implementation);

export const fetchProblemsByIds = (ids: string[]) =>
  Effect.gen(function* () {
    const api = yield* WrittenTestApiService;
    const problems = yield* api.fetchProblemsWithIds(ids);
    return problems;
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchExamProblems = (type: '미니모의고사' | '실전모의고사') =>
  Effect.gen(function* () {
    const { fetchExamProblems } = yield* WrittenTestApiService;
    return yield* fetchExamProblems(type);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchProblemsWithCategory = (
  category: WrittenTestProblemCategory,
  id: string,
  direction: string,
) =>
  Effect.gen(function* () {
    const { fetchProblemsWithCategory } = yield* WrittenTestApiService;
    return yield* fetchProblemsWithCategory(category, id, direction);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchFrequentlyMissedProblems = (id: string, direction: string) =>
  Effect.gen(function* () {
    const { fetchFrequentlyMissedProblems } = yield* WrittenTestApiService;
    return yield* fetchFrequentlyMissedProblems(id, direction);
  }).pipe(provideImplementation, Effect.runPromise);

export const saveExamResult = (data: ExamHistoryData) =>
  Effect.gen(function* () {
    const { saveExamResult } = yield* WrittenTestApiService;
    return yield* saveExamResult(data);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchUserExamHistory = () =>
  Effect.gen(function* () {
    const { fetchUserExamHistory } = yield* WrittenTestApiService;
    return yield* fetchUserExamHistory();
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchUserExamHistoryDetail = (id: string) =>
  Effect.gen(function* () {
    const { fetchUserExamHistoryDetail } = yield* WrittenTestApiService;
    return yield* fetchUserExamHistoryDetail(id);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchMissedSubmissions = () =>
  Effect.gen(function* () {
    const { fetchMissedSubmissions } = yield* WrittenTestApiService;
    return yield* fetchMissedSubmissions();
  }).pipe(provideImplementation, Effect.runPromise);

export const bookmarkProblem = (id: string) =>
  Effect.gen(function* () {
    const { bookmarkProblem } = yield* WrittenTestApiService;
    return yield* bookmarkProblem(id);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchBookmarkedProblems = () =>
  Effect.gen(function* () {
    const { fetchBookmarkedProblems } = yield* WrittenTestApiService;
    return yield* fetchBookmarkedProblems();
  }).pipe(provideImplementation, Effect.runPromise);

export const saveStudyProgress = (payload: SaveStudyProgressPayload) =>
  Effect.gen(function* () {
    const { saveStudyProgress } = yield* WrittenTestApiService;
    return yield* saveStudyProgress(payload);
  }).pipe(provideImplementation, Effect.runPromise);

export const fetchStudyProgress = () =>
  Effect.gen(function* () {
    const { fetchStudyProgress } = yield* WrittenTestApiService;
    return yield* fetchStudyProgress();
  }).pipe(provideImplementation, Effect.runPromise);
