import { useEffect, useMemo } from 'react';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { auth } from 'firebase-config';
import { useShallow } from 'zustand/react/shallow';

import type {
  ExamHistoryData,
  WrittenTestProblem,
  WrittenTestProblemCategory,
  WrittenTestProblems,
} from '@pages/api/v2/route/written-test/writtenTestSchema';

import { api } from '@apis/hc';

import {
  bookmarkProblem,
  fetchBookmarkedProblems,
  fetchExamProblems,
  fetchFrequentlyMissedProblems,
  fetchMissedSubmissions,
  fetchProblemsByIds,
  fetchProblemsWithCategory,
  fetchStudyProgress,
  fetchUserExamHistory,
  fetchUserExamHistoryDetail,
  saveExamResult,
  saveStudyProgress,
} from './effects';
import { useWrittenTestStore } from './store';

type PrevTestHistoryData = {
  date: string;
  id: string;
  isPassed: boolean;
  rightNumber: number;
  score: number;
};

const syncPrevWrittenTestHistory = async (data: {
  testHistory: PrevTestHistoryData[];
  bookmarkProblems: { id: number; createdAt: string }[];
}) => {
  const idToken = await auth.currentUser?.getIdToken();
  if (!idToken) return;

  const res = await api['written-test'].sync.prev.$post(
    {
      json: data,
    },
    {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    },
  );

  if (!res.ok) {
    throw new Error(`이전 필기시험 기록 동기화에 실패했습니다: ${res.statusText}`);
  }

  return res.json();
};

export const useGetProblemsByIds = (ids: string[]) => {
  const queryResult = useQuery({
    queryKey: ['exam', 'problems', ids],
    queryFn: () => fetchProblemsByIds(ids),
    enabled: Array.isArray(ids) && ids.length > 0,
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();
    queryResult?.data?.forEach((problem, index) => {
      problemMap.set(index, problem);
    });
    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useGetExamProblemsQuery = ({ type }: { type: '미니모의고사' | '실전모의고사' }) => {
  const examKey = useWrittenTestStore(useShallow((s) => s.examUniqueKey));

  const queryResult = useQuery({
    queryKey: ['test', 'exam', type, examKey],
    queryFn: () => fetchExamProblems(type),
    enabled: !!type,
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();
    queryResult?.data?.forEach((problem, index) => {
      problemMap.set(index, problem);
    });
    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useSaveExamResultMutation = (problems: WrittenTestProblems) => {
  const queryClient = useQueryClient();
  const problemsArray = Array.from(problems).map(([, value]) => value);

  const data: ExamHistoryData = useWrittenTestStore(
    useShallow((s) => ({
      type: s.examType,
      targetLicense: s.targetLicense,
      problems: problemsArray.map((p) => p?.id),
      submissions: s.submissions,
      result: s.result,
      completed:
        s.submissions.filter(
          (submission) => Array.isArray(submission?.attempt) && submission.attempt.length > 0,
        ).length === problems.size,
      startedAt: s.examStartedAt,
    })),
  );

  const { mutate, ...mutationResult } = useMutation({
    mutationFn: () => saveExamResult(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['exam', 'history'],
      });
    },
    onError: (error) => {
      console.error('모의고사 결과 저장 중 오류가 발생했습니다:', error);
    },
  });

  const save = () => {
    mutate();
  };

  return { ...mutationResult, save };
};

export const useGetUserExamHistoryQuery = () => {
  const queryResult = useQuery({
    queryKey: ['exam', 'history'],
    queryFn: fetchUserExamHistory,
  });

  return queryResult;
};

export const useGetUserExamHistoryDetailQuery = (id: string) => {
  const queryResult = useQuery({
    queryKey: ['exam', 'history', id],
    queryFn: () => fetchUserExamHistoryDetail(id),
    enabled: !!id,
  });

  return queryResult;
};

export const useGetMissedSubmissionsQuery = () => {
  const queryResult = useQuery({
    queryKey: ['test', 'problems', 'missed'],
    queryFn: fetchMissedSubmissions,
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();
    queryResult?.data?.forEach((problem, index) => {
      problemMap.set(index, problem);
    });
    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useBookmarkProblemMutation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['test', 'problem', 'bookmark', id],
    mutationFn: () => bookmarkProblem(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['test', 'problems', 'bookmarked'],
      });
    },
    onError: (error) => {
      console.error('문제 북마크 중 오류가 발생했습니다:', error);
    },
  });
};

export const useGetBookmarkedProblemsQuery = () => {
  const queryResult = useQuery({
    queryKey: ['test', 'problems', 'bookmarked'],
    queryFn: fetchBookmarkedProblems,
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();
    queryResult?.data?.forEach((problem, index) => {
      problemMap.set(index, problem);
    });
    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useGetProblemsByCategoryInfiniteQuery = (
  categoryProvided: WrittenTestProblemCategory,
  initial: {
    id: string;
    index: number;
  } = { id: 'initial', index: 0 },
) => {
  const category = categoryProvided as WrittenTestProblemCategory;

  const queryKey = ['test', 'problems', categoryProvided, initial.id, initial.index];

  const queryResult = useInfiniteQuery({
    queryKey,
    initialPageParam: { id: initial?.id ?? 'initial', direction: 'next' },
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      if (!lastPage?.length)
        return {
          id: 'initial',
          direction: 'next',
        };
      const problemId = lastPage[lastPage.length - 1].id;
      return { id: problemId, direction: 'next' };
    },
    getPreviousPageParam: (firstPage) => {
      if (!firstPage?.length)
        return {
          id: 'initial',
          direction: 'next',
        };
      const problemId = firstPage[0]?.id ?? 'initial';
      return { id: problemId, direction: 'prev' };
    },
    queryFn: async ({
      pageParam: { id, direction },
    }: {
      pageParam: {
        id: string;
        direction: string;
      };
    }) => {
      return fetchProblemsWithCategory(category, id, direction);
    },
    enabled: !!category,
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();

    const prevs = queryResult.data?.pages
      .filter((_, i) => queryResult.data.pageParams[i].direction === 'prev')
      .flat();

    const nexts = queryResult.data?.pages
      .filter((_, i) => queryResult.data.pageParams[i].direction === 'next')
      .flat();

    prevs?.forEach((problem, index, arr) => {
      const newIndex = index - arr.length;
      problemMap.set(newIndex, problem);
    });

    const uniqueNexts = Array.from(new Map(nexts?.map((item) => [item.id, item])).values());

    uniqueNexts?.forEach((problem, index) => {
      problemMap.set(index, problem);
    });
    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useGetFrequentlyMissedProblemsInfiniteQuery = (
  initial: {
    id: string;
    index: number;
  } = { id: 'initial', index: 0 },
) => {
  const queryKey = ['test', 'problems', 'frequently-missed', initial.id, initial.index];

  const queryResult = useInfiniteQuery({
    queryKey,
    initialPageParam: { id: initial?.id ?? 'initial', direction: 'next' },
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      if (!lastPage?.length)
        return {
          id: 'initial',
          direction: 'next',
        };
      const problemId = lastPage[lastPage.length - 1].id;
      return { id: problemId, direction: 'next' };
    },
    getPreviousPageParam: (firstPage) => {
      if (!firstPage?.length)
        return {
          id: 'initial',
          direction: 'next',
        };
      const problemId = firstPage[0]?.id ?? 'initial';
      return { id: problemId, direction: 'prev' };
    },
    queryFn: async ({
      pageParam: { id, direction },
    }: {
      pageParam: {
        id: string;
        direction: string;
      };
    }) => {
      return fetchFrequentlyMissedProblems(id, direction);
    },
  });

  const problems = useMemo(() => {
    const problemMap = new Map<string | number, WrittenTestProblem>();

    const prevs = queryResult.data?.pages
      .filter((_, i) => queryResult.data.pageParams[i].direction === 'prev')
      .flat();

    const nexts = queryResult.data?.pages
      .filter((_, i) => queryResult.data.pageParams[i].direction === 'next')
      .flat();

    const uniqueNexts = Array.from(new Map(nexts?.map((item) => [item.id, item])).values());

    prevs?.forEach((problem, index, arr) => {
      const newIndex = index - arr.length;
      problemMap.set(newIndex, problem);
    });

    uniqueNexts.forEach((problem, index) => {
      problemMap.set(index, problem);
    });

    return problemMap;
  }, [queryResult.data]);

  return { ...queryResult, problems };
};

export const useSyncPrevWrittenTestHistory = () => {
  const { mutate } = useMutation({
    mutationKey: ['user', 'prev', 'written-test', 'history'],
    mutationFn: syncPrevWrittenTestHistory,
    onSuccess: () => {
      localStorage.removeItem('testHistory');
      localStorage.removeItem('bookmarkProblems');
    },
    onError: (error) => {
      console.error('이전 필기시험 기록 동기화에 실패했습니다:', error);
    },
  });

  useEffect(() => {
    const testHistory = localStorage.getItem('testHistory');
    const bookmarkProblems = localStorage.getItem('bookmarkProblems');

    if (testHistory && bookmarkProblems) {
      try {
        const data = {
          testHistory: JSON.parse(testHistory).map((history: PrevTestHistoryData) => ({
            ...history,
            date: new Date(`20${history.date.split('/').join('-')}`).toISOString(),
          })),
          bookmarkProblems: JSON.parse(bookmarkProblems).map((id: string) => ({
            id,
            createdAt: new Date().toISOString(),
          })),
        };
        mutate(data);
      } catch (error) {
        console.error('이전 필기시험 기록 처리 중 오류가 발생했습니다:', error);
      }
    }
  }, [mutate]);
};

export const useSaveStudyProgressMutation = (
  type: WrittenTestProblemCategory | 'frequently-missed',
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['test', 'study', 'progress', type],
    mutationFn: saveStudyProgress,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['written-test', 'study', 'progress'] });
    },
    onError: (error) => {
      console.error('학습 진행 기록 저장 중 오류가 발생했습니다:', error);
    },
  });
};

export const useGetStudyProgress = () => {
  return useQuery({
    queryKey: ['written-test', 'study', 'progress'],
    queryFn: fetchStudyProgress,
  });
};
