import { createContext } from 'react';

import { produce } from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createJSONStorage, persist, redux } from 'zustand/middleware';

import type {
  WrittenTestProblem,
  WrittenTestProblemCategory,
  WrittenTestProblems,
} from '@pages/api/v2/route/written-test/writtenTestSchema';

const MINI_EXAM_SESSION_STORAGE_KEY = 'exam-mini-session';

const EXAM_TIME_LIMIT_IN_SECONDS = 2400;

export const PROBLEM_CATEGORY_COUNT_MAP: Record<WrittenTestProblemCategory, number> = {
  문장형1답: 583,
  문장형2답: 97,
  사진형: 100,
  안전표지형: 100,
  일러스트형: 85,
  동영상형: 35,
};

const MINI_EXAM_SCORE_MAP = {
  문장형: 9,
  안전표지형: 8,
  사진형: 18,
  일러스트형: 12,
  동영상형: 5,
} satisfies Record<WrittenTestProblem['type'], number>;

const FULL_EXAM_SCORE_MAP = {
  문장형: { '1답': 2, '2답': 3 },
  안전표지형: 2,
  사진형: 3,
  일러스트형: 3,
  동영상형: 5,
} satisfies Record<WrittenTestProblem['type'], number | { '1답': number; '2답': number }>;

type UIToggleState = '열림' | '닫힘';
type TestTargetLicense = '2종 보통' | '1종 보통' | '1종 대형';
type ExamType = '실전모의고사' | '미니모의고사';

type WrittenTestSubmission = {
  problem: {
    id: string;
    type: WrittenTestProblem['type'];
  };
  solution: string[];
  attempt: string[];
  isCorrect: boolean;
  createdAt: string;
};

export type WrittenTestState = {
  /**
   * 문제 풀기 UI 상태관련
   */
  mode: '모의고사' | '테스트' | '해설';
  examType: ExamType;
  timer: { state: '대기' | '시작' | '종료'; expireIn: number };
  targetLicense: TestTargetLicense;
  categorySelect: UIToggleState;
  licenseSelect: UIToggleState;
  submissions: WrittenTestSubmission[];
  result: {
    score: number;
    isPassed: boolean;
  };
  examStartedAt: string;
  /**
   * `ExamTimer` 컴포넌트의 `key`를 변경해 remount 시키는데 사용
   */
  examUniqueKey: number;
};

type WrittenTestAction =
  | { type: '종별선택열기' }
  | { type: '종별선택닫기' }
  | { type: '종별선택'; targetLicense: WrittenTestState['targetLicense'] }
  | { type: '유형선택열기' }
  | { type: '유형선택닫기' }
  | { type: '미니모의고사시작' }
  | { type: '실전모의고사시작' }
  | { type: '시험시간만료' }
  | {
      type: '객관식보기선택:단수';
      payload: { problemIndex: number; value: string; problem?: WrittenTestProblem };
    }
  | {
      type: '객관식보기선택:복수';
      payload: { problemIndex: number; value: string[]; problem?: WrittenTestProblem };
    }
  | { type: '문제스킵'; payload: { problemIndex: number; problem?: WrittenTestProblem } }
  | { type: '채점하기'; problems: WrittenTestProblems }
  | { type: '나가기' }
  | { type: '다시풀기'; payload: ExamType }
  | { type: '완료' }
  | { type: '복습시작' }
  | { type: '학습시작' }
  | { type: '모드변경'; payload: WrittenTestState['mode'] };

const writtenTestStoreReducer = (
  state: WrittenTestState,
  action: WrittenTestAction,
): WrittenTestState => {
  switch (action.type) {
    case '종별선택열기':
      return produce(state, (draft) => {
        draft.licenseSelect = '열림';
      });
    case '종별선택닫기':
      return produce(state, (draft) => {
        draft.licenseSelect = '닫힘';
      });
    case '종별선택':
      return produce(state, (draft) => {
        draft.targetLicense = action.targetLicense;
        draft.licenseSelect = '닫힘';
      });
    case '유형선택열기':
      return produce(state, (draft) => {
        draft.categorySelect = '열림';
      });
    case '유형선택닫기':
      return produce(state, (draft) => {
        draft.categorySelect = '닫힘';
      });
    case '객관식보기선택:단수':
      return produce(state, (draft) => {
        if (state.mode === '해설') {
          return;
        }

        const { problem, problemIndex, value } = action.payload;

        if (!problem) {
          return;
        }

        const isCorrect = problem.solution.every((s) => value.includes(s)) ?? false;

        draft.submissions[problemIndex] = {
          problem: {
            id: problem.id,
            type: problem.type,
          },
          isCorrect,
          solution: problem.solution,
          attempt: [value],
          createdAt: new Date().toISOString(),
        };
      });
    case '객관식보기선택:복수':
      return produce(state, (draft) => {
        if (state.mode === '해설') {
          return;
        }

        const { problem, problemIndex, value } = action.payload;

        if (!problem) {
          return;
        }

        const isCorrect = problem.solution.every((s) => value.includes(s)) ?? false;

        draft.submissions[problemIndex] = {
          problem: {
            id: problem.id,
            type: problem.type,
          },
          isCorrect,
          solution: problem.solution,
          attempt: value,
          createdAt: new Date().toISOString(),
        };
      });
    case '문제스킵':
      return produce(state, (draft) => {
        const { problemIndex, problem } = action.payload;

        if (!problem) {
          return;
        }

        draft.submissions[problemIndex] = {
          problem: {
            id: problem.id,
            type: problem.type,
          },
          solution: problem.solution,
          attempt: [],
          isCorrect: false,
          createdAt: new Date().toISOString(),
        };
      });
    case '채점하기':
      return produce(state, (draft) => {
        const problems = action.problems;
        const submissions = state.submissions;
        const isMiniExam = state.mode === '모의고사' && state.examType === '미니모의고사';

        const totalScore = submissions.reduce((total, submission, index) => {
          const problemType = problems.get(index)?.type;
          const isMultiple = (problems.get(index)?.solution.length ?? 0) > 1;
          const isCorrect = submission?.isCorrect;
          const score =
            isCorrect && problemType
              ? isMiniExam
                ? MINI_EXAM_SCORE_MAP[problemType]
                : problemType === '문장형'
                ? FULL_EXAM_SCORE_MAP[problemType][isMultiple ? '2답' : '1답']
                : FULL_EXAM_SCORE_MAP[problemType]
              : 0;
          return total + score;
        }, 0);

        draft.result.score = totalScore;
        draft.result.isPassed =
          draft.targetLicense === '2종 보통' ? totalScore >= 60 : totalScore >= 70;
      });
    case '나가기':
      return getTestStoreInitialState();
    case '미니모의고사시작':
      return {
        ...getTestStoreInitialState(),
        mode: '모의고사',
        examType: '미니모의고사',
        examStartedAt: new Date().toISOString(),
      };
    case '실전모의고사시작':
      return {
        ...getTestStoreInitialState(),
        mode: '모의고사',
        examType: '실전모의고사',
        timer: { state: '시작', expireIn: EXAM_TIME_LIMIT_IN_SECONDS },
        examStartedAt: new Date().toISOString(),
      };
    case '시험시간만료':
      return produce(state, (draft) => {
        draft.timer.state = '종료';
      });
    case '다시풀기':
      return {
        ...getTestStoreInitialState(),
        result: state.result,
        mode: '모의고사',
        examType: action.payload,
        examStartedAt: new Date().toISOString(),
        examUniqueKey: state.examUniqueKey + 1,
        timer:
          action.payload === '실전모의고사'
            ? {
                state: '시작',
                expireIn: EXAM_TIME_LIMIT_IN_SECONDS,
              }
            : {
                state: '대기',
                expireIn: 0,
              },
      };
    case '학습시작':
      return produce(state, (draft) => {
        draft.mode = '해설';
        draft.submissions = [];
        draft.categorySelect = '닫힘';
      });
    case '복습시작':
      return produce(state, (draft) => {
        draft.mode = '해설';
        draft.submissions = [];
      });
    case '모드변경':
      return produce(state, (draft) => {
        draft.mode = action.payload;
        if (action.payload === '해설') {
          draft.submissions = [];
        }
      });
    case '완료':
      return produce(state, (draft) => {
        draft.submissions = [];
      });
    default:
      return state;
  }
};

const getTestStoreInitialState = (): WrittenTestState => ({
  mode: '모의고사',
  examType: '실전모의고사',
  timer: { state: '대기', expireIn: EXAM_TIME_LIMIT_IN_SECONDS },
  targetLicense:
    (globalThis.localStorage?.getItem('targetLicense') as TestTargetLicense) || '2종 보통',
  categorySelect: '닫힘',
  licenseSelect: '닫힘',
  submissions: [],
  result: {
    score: 0,
    isPassed: false,
  },
  examStartedAt: new Date().toISOString(),
  examUniqueKey: 0,
});

const isDev = process.env.NODE_ENV === 'development';
export const useWrittenTestStore = isDev
  ? create(
      devtools(
        persist(redux(writtenTestStoreReducer, getTestStoreInitialState()), {
          name: MINI_EXAM_SESSION_STORAGE_KEY,
          storage: createJSONStorage(() => sessionStorage),
        }),
      ),
    )
  : create(
      persist(redux(writtenTestStoreReducer, getTestStoreInitialState()), {
        name: MINI_EXAM_SESSION_STORAGE_KEY,
        storage: createJSONStorage(() => sessionStorage),
      }),
    );

export const WrittenTestProblemsContext = createContext<Map<
  string | number,
  WrittenTestProblem
> | null>(null);
export const WrittenTestProblemsProvider = WrittenTestProblemsContext.Provider;
